import React from 'react'
import Layout from '../components/Layout/Layout'
import Banner from '../components/Banner/Banner'

export const wineClubPageData = graphql`
  query wineClubPageQuery {
    sanityPage(slug: {current: {eq: "wine-club"}}) {
      pageBanner {
        title
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
	  }
  }
`

const wineClubPage = ({data}) => {
    return (
        <Layout>
            <Banner 
                heading={ data.sanityPage.pageBanner.title }
                image={ data.sanityPage.pageBanner.image.asset.fluid }
                bannerAlt="banner--sm"
            />
        </Layout>
    )
}

export default wineClubPage
